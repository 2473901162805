import AuthService from '@/core/services/authentication.service';
import ValidationService from '@/core/services/validation.service';
/**
 * Email validator for Registration
 *
 * @param rule
 * @param value
 * @param cb
 * @returns
 */
// eslint-disable-next-line no-unused-vars
export const emailValidator = (rule, value, cb) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value === undefined || value.length === 0) {
        cb((new Error('Enter your email address.')));
    }
    if (re.test(String(value).toLowerCase()) !== false) {
        AuthService.authenticateEmail(value).then((response) => {
            if (!response.valid) {
                cb(new Error(response.data.message));
            }
            else {
                cb();
            }
        })
            .catch((e) => {
            cb(new Error(e.data.message));
        });
    }
    else {
        cb(new Error('Invalid email address.'));
    }
};
/**
 * Validates the postcode entered by user
 *
 * @param rule
 * @param value
 * @param cb
 */
// Disable the type since we know the impact
// eslint-disable-next-line no-unused-vars
export const postcodeValidator = (rule, value, cb) => {
    const postcode = (value ?? '').trim();
    if (postcode && postcode.length) {
        ValidationService.validatePostcode(postcode).then((valid) => {
            if (valid === true) {
                cb();
            }
            else {
                cb(new Error('Valid Postcode is required.'));
            }
        }).catch(() => {
            cb(new Error('Valid Postcode is required.'));
        });
    }
    else {
        cb(new Error('Valid Postcode is required.'));
    }
};
/**
 * Validates phone UK number only
 * @param rule
 * @param value
 * @param cb
 */
// Disable the type since we know the impact
// eslint-disable-next-line no-unused-vars
export const phoneUKValidator = (rule, value, cb) => {
    if (value === undefined || value.length === 0) {
        cb((new Error('Enter a valid UK phone number.')));
    }
    if (value.length && value != null) {
        ValidationService.validatePhoneNumber(value).then((response) => {
            if (response.success === false || response.valid === false) {
                cb(new Error('Enter a valid UK phone number.'));
            }
            else {
                cb();
            }
        });
    }
    else {
        cb(new Error('Enter a valid UK phone number.'));
    }
};
/**
 * Validates User Referral Code exists in users table
 * @param rule
 * @param value
 * @param cb
 */
// Disable the type since we know the impact
// eslint-disable-next-line no-unused-vars
export const referralCodeValidator = (rule, value, cb) => {
    const referralCode = (value || '').trim();
    if (Number.isNaN(referralCode)) {
        cb('Only digits are allowed in referral code');
    }
    ValidationService.validateReferralCode(value)
        .then((response) => {
        if (response && (response.data === true)) {
            cb();
        }
        cb(new Error('Invalid Referral Code'));
    }).catch(() => {
        cb(new Error('Invalid Referral Code'));
    });
    cb();
};
