<template>
    <el-button
    type="primary"
    class="button default-text flex-row ai-c jc-c"
    :class="{ 'active': isActive, 'transparent': isTransparent }"
    @click="handleClick"
    :loading="loading"
    :disabled="loading || (!isActive && !isTransparent)" round>
      {{ buttonText }}
    </el-button>
</template>
<script>
export default {
  emits: ['handle-click'],
  props: {
    buttonText: {
      type: String,
      default: 'next'
    },
    loading: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    activeColor: {
      type: String,
      default: '#FFA500'
    },
    activeFontColor: {
      type: String,
      default: '#FFF'
    },
    transparentFontColor: {
      type: String,
      default: 'rgba(13, 16, 74, 0.5)'
    },
    defaultBgColor: {
      type: String,
      default: 'rgba(12, 15, 74, 0.1)'
    },
    defaultFontColor: {
      type: String,
      default: 'rgba(12, 15, 74, 0.5)'
    },
    isTransparent: {
      type: Boolean,
      default: false
    },
    borderColor: {
      type: String,
      default: 'transparent'
    },
  },
  methods: {
    handleClick() {
      this.$emit('handle-click');
    }
  },
};
</script>
<style lang="scss" scoped>
.button {
    height: 42px;
    border-radius: 8px;
    background: v-bind('$props.defaultBgColor');
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: v-bind('$props.defaultFontColor');
    font-family: Mulish;
    border: none;
    text-transform: uppercase;
    border: 1px solid v-bind('$props.borderColor');
    box-sizing: border-box;
    // width: 100%;

    &:hover {
        background: v-bind('$props.defaultBgColor');;
        color: v-bind('$props.defaultFontColor');;
    }
}
.button.active {
    background: v-bind('$props.activeColor');
    color: v-bind('$props.activeFontColor');

    &:hover {
        background: v-bind('$props.activeColor');
        color: v-bind('$props.activeFontColor');
    }
}
.button.transparent {
  background: transparent;
  color: v-bind('$props.transparentFontColor');

  &:hover {
    background: transparent;
    color: v-bind('$props.transparentFontColor');
  }
}
</style>
