<template>
  <BaseModal
    :show="show"
    :showCancelBtn="false"
    :showSubmitBtn="false"
    :showDefaultCloseBtn="false"
    @cancel="cancel"
    :hasActions="false"
    >
    <div class="add-client flex-column jc-sb">
        <div class="add-client__container flex-column">
          <div class="add-client__header flex-row ai-c jc-sb">
              <div class="flex-column ai-fs">
                <h2 class="add-client__title default-text">{{ isOnEditModal ? 'Edit client' : 'Add a client' }}</h2>
                <p class="add-client__description default-text" v-if="!isOnEditModal">Add new client that you’d like to send an estimate to</p>
              </div>
              <inline-svg class="cursor-pointer" @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
          </div>
          <div class="add-client__body">
            <el-form
                class="flex-column"
                ref="addClientForm"
                :model="jobClientForm"
                :rules="rules"
                size="small"
                status-icon
                :validate-on-rule-change="true"
                label-position="top">
                <div class="form-container flex-column jc-sb">
                  <section class="form-container__content flex-column">
                    <el-form-item label="Full name" prop="name">
                      <el-input v-model="jobClientForm.name" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="Email" prop="email">
                      <el-input v-model="jobClientForm.email" clearable v-debounce:150="onChangeEmail" :disabled="isOnEditModal"></el-input>
                    </el-form-item>
                    <div v-if="!isOnEditModal" class="form-container__content flex-column">
                      <el-form-item label="Address Line" prop="addressLine">
                        <el-input v-model="jobClientForm.addressLine" clearable></el-input>
                      </el-form-item>
                      <el-form-item label="City/Town" prop="cityTown">
                        <el-input v-model="jobClientForm.cityTown" clearable></el-input>
                      </el-form-item>
                      <el-form-item label="Postcode" prop="postcode">
                        <el-input v-model="jobClientForm.postcode" clearable></el-input>
                      </el-form-item>
                    </div>
                  </section>
                  <section class="form-container__actions">
                    <Button :isActive="isFormReady" :buttonText="isOnEditModal ? 'Edit Client' : 'Add Client'" :loading="saving" @handle-click="onSave"/>
                  </section>
                </div>
            </el-form>
          </div>
        </div>
    </div>
  </BaseModal>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';

import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';
import { GENERIC_ERROR_MESSAGE } from '@/core/constants/messages';
import {
  emailValidator, postcodeValidator
} from '@/modules/registration/validators/registration.validator';
import { JOB_CLIENT_STORE } from '@/store/modules/job-client';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    BaseModal,
    Button
  },

  emits: [
    'on-cancel',
    'push-to-client-list',
    'update-client-list',
    'push-to-address-list'
  ],

  props: ['show'],

  data() {
    return {
      validEmail: false,

      validPostcode: false,

      saving: false,

      isFormReady: false,
      rules: {
        email: [
          {
            required: true,
            // eslint-disable-next-line no-unused-vars
            validator: (rule, value, cb) => {
              if (this.validEmail) {
                cb();
              }

              emailValidator(rule, value, (err) => {
                if (err) {
                  cb(err);
                } else {
                  this.validEmail = true;

                  cb();
                }
              });
            },
            trigger: 'blur'
          },
          { max: 255, message: 'Please enter a maximum of 255 characters.', trigger: 'blur' },
        ],

        name: [
          { required: true, message: 'Enter your full name.', trigger: 'blur' },
          { max: 255, message: 'Please enter a maximum of 255 characters.', trigger: 'blur' },
        ],

        postcode: [
          {
            required: true,
            // eslint-disable-next-line no-unused-vars
            validator: (rule, value, cb) => {
              if (this.validPostcode) {
                cb();
              } else {
                postcodeValidator(rule, value, (err) => {
                  if (err) {
                    cb(err);
                  } else {
                    this.validPostcode = true;
                    cb();
                  }
                });
              }
            },
            trigger: 'blur'
          }
        ],

        addressLine: [
          {
            required: true,
            message: 'Enter your address line.',
            trigger: 'blur',
          },
        ],

        cityTown: [
          {
            required: true,
            message: 'Enter your City/Town',
            trigger: 'blur',
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters(JOB_CLIENT_STORE, ['jobClientForm', 'selectedClient']),
    ...mapGetters(USERS_STORE, ['user']),

    isOnEditModal() {
      return this.selectedClient && this.selectedClient !== null;
    }
  },

  watch: {
    jobClientForm: {
      immediate: true,
      deep: true,
      handler() {
        const {
          email,
          name,
          addressLine,
          cityTown,
          postcode
        } = this.jobClientForm;

        if (email !== '' && name !== '' && addressLine !== '' && cityTown !== '' && postcode !== '') {
          this.isFormReady = true;
        } else {
          this.isFormReady = false;
        }
      }
    },

    selectedClient: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && value !== null) {
          this.initialize();
        }
      }
    }
  },

  methods: {
    ...mapActions(JOB_CLIENT_STORE, [
      'addJobClient',
      'resetJobClientFormState',
      'setJobClientForm',
      'updateJobClient',
      'addOrUpdateClientAddress'
    ]),

    cancel() {
      this.$emit('on-cancel');
    },

    onChangeEmail() {
      this.validEmail = false;
    },

    initialize() {
      if (this.isOnEditModal) {
        const { email } = this.selectedClient;
        const {
          name,
          addressLine,
          cityTown,
          postcode
        } = this.selectedClient?.meta;

        const form = {
          email,
          name,
          addressLine,
          cityTown,
          postcode
        };

        this.setJobClientForm({ ...form });
      }
    },

    onSave() {
      this.saving = true;
      this.$refs.addClientForm.validate((valid) => {
        if (valid) {
          const userId = this.user.id;
          const {
            email,
            name,
            addressLine,
            postcode,
            cityTown
          } = this.jobClientForm;
          const clientData = { name };
          const addressData = { postcode, addressLine, cityTown };

          delete this.jobClientForm.email;

          const meta = { ...clientData };
          let formData = {};
          let params = {};

          // separate client details and address

          if (this.isOnEditModal) {
            const { id, invitationStatus } = this.selectedClient;
            formData = {
              invitationStatus,
              meta
            };
            params = { userId, id, formData };
            this.updateClient(params);
          } else {
            formData = {
              email,
              meta
            };

            params = { userId, formData };

            this.addClient(params, addressData);
          }
        } else {
          this.saving = false;
          this.$notify.error({
            title: 'Please enter a valid information',
            message: 'There is something wrong with the information.',
          });
        }
      });
    },

    async addClient(params, addressData) {
      await this.addJobClient(params)
        .then(async (response) => {
          if (response) {
            this.$emit('push-to-client-list', response);
            await this.addOrUpdateAddress(addressData, response?.id);
            this.cancel();
            this.resetJobClientFormState();
            this.$notify.success({
              title: 'Success.',
              message: 'Client added successfully.',
            });
          }
        })
        .catch((e) => {
          this.$notify.error({
            title: 'Failed adding a client',
            message: e?.data?.message || GENERIC_ERROR_MESSAGE,
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },

    async addOrUpdateAddress(addressData, contractorId) {
      const userId = this.user.id;
      const formData = {
        meta: { ...addressData }
      };
      const params = { userId, contractorId, formData };
      await this.addOrUpdateClientAddress(params)
        .then(async (response) => {
          if (response) {
            this.$emit('push-to-address-list', response);
          }
        })
        .catch(() => {
          this.$notify.error({
            title: `Failed ${this.isOnEditModal ? 'editing' : 'adding'} an address to this client`,
            message: GENERIC_ERROR_MESSAGE,
          });
        });
    },

    async updateClient(params) {
      await this.updateJobClient(params)
        .then(async (response) => {
          if (response) {
            this.$emit('update-client-list', response);
            this.cancel();
            this.resetJobClientFormState();
            this.$notify.success({
              title: 'Success.',
              message: 'Client updated successfully.',
            });
          }
        })
        .catch((e) => {
          this.$notify.error({
            title: 'Failed updating client',
            message: e?.data?.message || GENERIC_ERROR_MESSAGE,
          });
        })
        .finally(() => {
          this.saving = false;
        });
    }
  },
});
</script>
<style lang="scss" scoped>
 .add-client {
    min-width: 500px;
    min-height: 700px;
    position: relative;
    height: 700px;
    &__container {
      gap: 1.5rem;
      height: 100%;
    }

    &__body {
      height: 100%;
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
    }

    &__description {
      color: rgba(12, 15, 74, 0.50);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      letter-spacing: 0.25px;
    }

    &__actions {
        width: 100%;
        height: auto;
    }
 }

.el-form {
  height: 100%;
    .form-container {
      height: 100%;

      &__wrapper {
        width: 100%;
      }

      &__content {
        max-height: calc(100% - 5rem);
        height: 100%;
        overflow: auto;
      }

      &__actions {
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }
    .el-form-item {
        margin: 0;

       :deep(.el-form-item__label) {
           font-family: Mulish;
            font-size: 16px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0.25px;
            text-align: left;
            color: #0C0F4A;
       }

       :deep(.el-input) {
          width: 100%;
          border-radius: 100px;
          height: 42px;
          background: rgba(12, 15, 74, 0.05);

          .el-input__inner {
            border-radius: 100px;
            outline:none;
            border: none;
            padding: 20px;
            background: transparent;
          }
       }

      :deep(.el-input__suffix) {
        right:20px;
        top: 5px;
        color:#111;
        display: inline-block !important;
      }
    }
}
</style>
