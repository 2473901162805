import $axios from '../utils/axios-api-config';
export default {
    /**
     * Validates if UK phone number
     * @param value
     * @returns
     */
    validatePhoneNumber(value) {
        return $axios.get(`/validate/phone-number/${value}`);
    },
    /**
     * Validates if valid UK postcode
     * @param value
     * @param params
     * @returns
     */
    validatePostcode(value, params) {
        return $axios.get(`/validate/postcode/${value}`, { params });
    },
    /**
     * Validates if valid User referral code exists
     * @param value
     * @param params
     * @returns
     */
    validateReferralCode(value, params) {
        return $axios.get(`/auth/referralcode/${value}`, { params });
    }
};
