<template>
  <BaseModal
    :show="show"
    :showCancelBtn="false"
    :showSubmitBtn="false"
    :showDefaultCloseBtn="false"
    @cancel="cancel"
    :hasActions="false"
    >
    <div class="add-address flex-column jc-sb">
        <div class="add-address__container flex-column">
          <div class="add-address__header flex-row ai-c jc-sb">
              <div class="flex-column ai-fs">
                <h2 class="add-address__title default-text">{{ isOnEditModal ? 'Edit address' : 'Add address' }}</h2>
                <p class="add-address__description default-text" v-if="!isOnEditModal">Add new address</p>
              </div>
              <inline-svg class="cursor-pointer" @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
          </div>
          <div class="add-address__body">
            <el-form
                class="flex-column"
                ref="addAddressForm"
                :model="addressForm"
                :rules="rules"
                size="small"
                status-icon
                :validate-on-rule-change="true"
                label-position="top">
                <div class="form-container flex-column jc-sb">
                  <section class="form-container__content flex-column">
                    <el-form-item label="Address Line" prop="addressLine">
                      <el-input v-model="addressForm.addressLine" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="City/Town" prop="cityTown">
                      <el-input v-model="addressForm.cityTown" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="Postcode" prop="postcode">
                      <el-input v-model="addressForm.postcode" clearable></el-input>
                    </el-form-item>
                  </section>
                  <section class="form-container__actions">
                    <Button :isActive="isFormReady" :loading="saving" :buttonText="isOnEditModal ? 'Edit Address' : 'Add Address'" @handle-click="onSave"/>
                  </section>
                </div>
            </el-form>
          </div>
        </div>
    </div>
  </BaseModal>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';

import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';
import { GENERIC_ERROR_MESSAGE } from '@/core/constants/messages';
import { postcodeValidator } from '@/modules/registration/validators/registration.validator';
import { JOB_CLIENT_STORE } from '@/store/modules/job-client';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    BaseModal,
    Button
  },

  emits: ['on-cancel', 'push-to-address-list', 'update-address-list'],

  props: ['show'],

  data() {
    return {
      validPostcode: false,

      saving: false,

      isFormReady: false,
      rules: {
        postcode: [
          {
            required: true,
            // eslint-disable-next-line no-unused-vars
            validator: (rule, value, cb) => {
              if (this.validPostcode) {
                cb();
              } else {
                postcodeValidator(rule, value, (err) => {
                  if (err) {
                    cb(err);
                  } else {
                    this.validPostcode = true;
                    cb();
                  }
                });
              }
            },
            trigger: 'blur'
          }
        ],

        addressLine: [
          {
            required: true,
            message: 'Enter your address line.',
            trigger: 'blur',
          },
        ],

        cityTown: [
          {
            required: true,
            message: 'Enter your City/Town',
            trigger: 'blur',
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
    ...mapGetters(JOB_CLIENT_STORE, ['selectedId', 'addressForm', 'selectedAddress']),

    isOnEditModal() {
      return this.selectedAddress && this.selectedAddress !== null;
    }
  },

  watch: {
    addressForm: {
      immediate: true,
      deep: true,
      handler() {
        const {
          addressLine,
          cityTown,
          postcode
        } = this.addressForm;

        if (addressLine !== '' && cityTown !== '' && postcode !== '') {
          this.isFormReady = true;
        } else {
          this.isFormReady = false;
        }
      }
    },

    selectedAddress: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && value !== null) {
          this.initialize();
        }
      }
    }
  },

  methods: {
    ...mapActions(JOB_CLIENT_STORE, ['addOrUpdateClientAddress', 'resetAddressFormState', 'setSelectedId', 'setAddressForm', 'setSelectedAddressId']),

    cancel() {
      this.$emit('on-cancel');
    },

    initialize() {
      if (this.isOnEditModal) {
        const {
          addressLine,
          cityTown,
          postcode
        } = this.selectedAddress?.meta;

        const form = {
          addressLine,
          cityTown,
          postcode
        };

        this.setAddressForm({ ...form });
      }
    },

    onSave() {
      this.saving = true;
      this.$refs.addAddressForm.validate((valid) => {
        if (valid) {
          const userId = this.user.id;
          const contractorId = this.selectedId;

          const meta = { ...this.addressForm };

          let formData = {};

          if (this.isOnEditModal) {
            const { id } = this.selectedAddress;
            formData = {
              id,
              meta
            };
          } else {
            formData = {
              meta
            };
          }

          const params = { userId, contractorId, formData };

          this.addOrUpdateAddress(params);
        } else {
          this.saving = false;
          this.$notify.error({
            title: 'Please enter a valid information',
            message: 'There is something wrong with the information.',
          });
        }
      });
    },

    async addOrUpdateAddress(params) {
      await this.addOrUpdateClientAddress(params)
        .then(async (response) => {
          if (response) {
            this.cancel();
            if (!this.isOnEditModal) {
              this.$emit('push-to-address-list', response);
            } else {
              this.$emit('update-address-list', response.addresses);
            }
            this.resetAddressFormState();
            this.setAddressForm({
              addressLine: '',
              cityTown: '',
              postcode: ''
            });
            this.$notify.success({
              title: 'Success.',
              message: `You successfully ${this.isOnEditModal ? 'edited' : 'added'} an address to this client.`,
            });
          }
        })
        .catch((e) => {
          this.$notify.error({
            title: `Failed ${this.isOnEditModal ? 'editing' : 'adding'} an address to this client`,
            message: e?.data?.message || GENERIC_ERROR_MESSAGE,
          });
        })
        .finally(() => {
          this.saving = false;
        });
    }
  },
});
</script>
<style lang="scss" scoped>
 .add-address {
    min-width: 500px;
    min-height: 700px;
    position: relative;
    height: 700px;
    &__container {
      gap: 1.5rem;
      height: 100%;
    }

    &__body {
      height: 100%;
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
    }

    &__description {
      color: rgba(12, 15, 74, 0.50);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      letter-spacing: 0.25px;
    }

    &__actions {
        width: 100%;
        height: auto;
    }
 }

.el-form {
  height: 100%;
    .form-container {
      height: 100%;

      &__wrapper {
        width: 100%;
      }

      &__content {
        max-height: calc(100% - 5rem);
        height: 100%;
        overflow: auto;
      }

      &__actions {
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }
    .el-form-item {
        margin: 0;

       :deep(.el-form-item__label) {
           font-family: Mulish;
            font-size: 16px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0.25px;
            text-align: left;
            color: #0C0F4A;
       }

       :deep(.el-input) {
          width: 100%;
          border-radius: 100px;
          height: 42px;
          background: rgba(12, 15, 74, 0.05);

          .el-input__inner {
            border-radius: 100px;
            outline:none;
            border: none;
            padding: 20px;
            background: transparent;
          }
       }

      :deep(.el-input__suffix) {
        right:20px;
        top: 5px;
        color:#111;
        display: inline-block !important;
      }
    }
}
</style>
