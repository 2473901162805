<template>
    <DefaultCardSlot class="user-tile-view" @handle-event="handleEvent($event, data)" :popOverData="popOverData" :showDropdownMenu="true" :hasCustomHeader="true" cardHeight="181px">
       <template #custom-header>
           <LabeledThumbnail :hasCustomLabel="true" iconSize="60px" :img="data?.toUser?.userPublicProfile?.profileImage">
            <template #custom-label>
             <div class="flex-column" style="gap: 0">
                <p class="user-tile-view__text bold default-text">{{ data?.meta?.name }}</p>
              </div>
             </template>
           </LabeledThumbnail>
       </template>
       <div class="flex-column" style="gap: 0">
          <p class="user-tile-view__text small bold default-text">Email</p>
          <p class="user-tile-view__text small default-text">{{ data?.email }}</p>
        </div>
    </DefaultCardSlot>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
import LabeledThumbnail from '@/core/components/ui/LabeledThumbnail.vue';
import { JOB_CLIENT_STORE } from '@/store/modules/job-client';

const DELETE = 'Delete';
const CHAT = 'Chat';
const EDIT = 'Edit';
const VIEW = 'View';

export default defineComponent({
  components: {
    DefaultCardSlot,
    LabeledThumbnail
  },

  emits: ['open-edit-modal', 'on-select'],
  props: ['data'],

  data() {
    return {
      /* eslint-disable global-require */
      popOverData: [
        {
          icon: `${require('@/assets/icons/view-icon.svg')}`,
          name: 'View',
        },
        {
          icon: `${require('@/assets/icons/filled-edit-icon.svg')}`,
          name: 'Edit',
        },
        {
          icon: `${require('@/assets/icons/chat.svg')}`,
          name: 'Chat',
        },
        {
          icon: `${require('@/assets/icons/delete-icon.svg')}`,
          name: 'Delete',
        },
      ],
      /* eslint-disable global-require */
    };
  },

  computed: {
    ...mapGetters(JOB_CLIENT_STORE, ['selectedId', 'confirmDeleteModal']),
  },

  methods: {
    ...mapActions(JOB_CLIENT_STORE, ['deleteClient', 'setSelectedId', 'setConfirmDeleteModal', 'setSelectedClient']),

    handleEvent(eventName, data) {
      if (eventName === DELETE) {
        this.setSelectedId(data.id);
        this.setConfirmDeleteModal(true);
      }
      if (eventName === EDIT) {
        this.setSelectedClient(data);
        this.$emit('open-edit-modal');
      }
      if (eventName === CHAT) {
        // this.deleteClient(id);
      }
      if (eventName === VIEW) {
        this.$emit('on-select', data);
      }
    },
  }
});
</script>
<style lang="scss" scoped>
.user-tile-view {
 &__text {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.1px;
 }
 &__text.bold {
    font-weight: 700;
 }
 &__text.small {
    font-size: 14px;
 }
}
</style>
