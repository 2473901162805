<template>
    <DefaultCardSlot class="address-tile-view" @handle-event="handleEvent($event, data)" :popOverData="popOverData" :showDropdownMenu="true" :hasCustomHeader="true" cardHeight="181px">
       <template #custom-header>
          <div class="flex-column" style="gap: 0">
            <p class="address-tile-view__text bold default-text">{{ formattedAddress }}</p>
            <div class="flex-row ai-c gap-half">
              <el-icon class="el-input__icon"><LocationFilled /></el-icon>
              <p class="address-tile-view__text default-text">{{ data?.meta?.postcode }}</p>
            </div>
          </div>
       </template>
       <div class="flex-row ai-c jc-c" style="width: 100%;">
          <Button isActive="true" activeColor="#4F55F0" buttonText="select"/>
        </div>
    </DefaultCardSlot>
</template>
<script>
import { LocationFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
import Button from '@/core/components/ui/Button.vue';
// import LabeledThumbnail from '@/core/components/ui/LabeledThumbnail.vue';
import { JOB_CLIENT_STORE } from '@/store/modules/job-client';
import { USERS_STORE } from '@/store/modules/users';

const DELETE = 'Delete';
const EDIT = 'Edit';

export default defineComponent({
  components: {
    DefaultCardSlot,
    // LabeledThumbnail,
    Button,
    LocationFilled
  },
  emits: ['open-edit-address-modal'],
  props: ['data'],

  data() {
    return {
      /* eslint-disable global-require */
      popOverData: [
        {
          icon: `${require('@/assets/icons/filled-edit-icon.svg')}`,
          name: 'Edit',
        },
        {
          icon: `${require('@/assets/icons/chat.svg')}`,
          name: 'Chat',
        },
        {
          icon: `${require('@/assets/icons/delete-icon.svg')}`,
          name: 'Delete',
        },
      ],
      /* eslint-disable global-require */
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
    ...mapGetters(JOB_CLIENT_STORE, ['selectedId', 'confirmDeleteAddressModal']),

    formattedAddress() {
      const { addressLine, cityTown } = this.data.meta;
      return `${addressLine}, ${cityTown}`;
    }
  },

  methods: {
    ...mapActions(JOB_CLIENT_STORE, ['setSelectedId', 'setConfirmDeleteAddressModal', 'setSelectedClient', 'setSelectedAddressId', 'setSelectedAddress']),

    handleEvent(eventName, address) {
      if (eventName === DELETE) {
        this.setSelectedAddressId(address.id);
        this.setConfirmDeleteAddressModal(true);
      }
      if (eventName === EDIT) {
        this.setSelectedAddress(address);
        this.$emit('open-edit-address-modal');
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.address-tile-view {
 &__text {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.1px;
 }
 &__text.bold {
    font-weight: 700;
 }
 &__text.small {
    font-size: 14px;
 }
}
</style>
