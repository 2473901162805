<template>
    <div class="empty-state flex-column ai-c jc-c">
      <img :src="require(`@/assets/icons/` + icon)" v-if="icon"/>
      <p class="empty-state__message default-text" v-if="message">{{ message }}</p>
      <template v-if="hasCustomContent">
        <slot></slot>
      </template>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    message: {
      type: String,
    },
    icon: {
      type: String,
    },
    hasCustomContent: Boolean
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.empty-state {
  width: auto;
  height: auto;
  position: absolute;
  top: calc(50% - 80px);
  left: calc(50% - 70px);
  gap: .5rem;

  &__message {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    color: rgba(12, 15, 74, 0.5);
    text-align:center;
    width: 70%;
  }
}

@include media-sm-max-width {
  .empty-state {
    width: 130px;
    height: 130px;
    top: calc(70% - 80px);
  }
}

@media screen and (max-height: 700px) {
  .empty-state {
    top: calc(80% - 80px);
  }
}
</style>
