<template>
    <div class="clients-page flex-column">
        <section class="clients-page__container sticky flex-row ai-c jc-sb">
          <BackButton :isNotRouteLevel="true" @back="back" v-if="isOnViewDetailsScreen"/>
          <PageTitleWithCountComponent title="Clients" :totalItemCount="clients.length" v-if="!isOnViewDetailsScreen"/>
          <DisplayViewComponent
            :viewType="viewType"
            :popOverData="popOverData"
            :showSort="true"
            @sort="onSort($event)"
            @set-view="setView"
            :showAddButton="true"
            :actionText="actionText"
            @add-function="onOpenModal"/>
        </section>
        <section class="clients-page__container" :class="{'grid': viewType === display.TILE}" v-if="clients.length && !isOnViewDetailsScreen && !loadingContractors">
          <ClientsList
            :clients="clients"
            v-if="viewType === display.LIST"
            @on-select="onSelect($event)"
            @show-delete-modal="onDeleteClient($event)"
            @open-edit-modal="openEditModal"/>
          <template v-if="viewType === display.TILE">
            <UserTileViewItem v-for="(client, index) in clients" :key="index"
              :data="client"
              @open-edit-modal="openEditModal"
              @on-select="onSelect($event)"/>
          </template>
        </section>
        <!-- IF SELECTED CLIENT -->
        <section class="clients-page__container gap-2 flex-column" v-if="clients.length && isOnViewDetailsScreen && !loadingContractors">
           <div class="clients-page__container grid">
             <UserTileViewItem :data="selectedClient" @open-edit-modal="openEditModal"/>
           </div>
           <div class="clients-page__container flex-row ai-c jc-sb">
              <PageTitleWithCountComponent title="Address"
                :totalItemCount="selectedClient.addresses && selectedClient.addresses.length ? selectedClient.addresses.length : 0"/>
            </div>
            <div class="clients-page__container" :class="{'grid': viewType === display.TILE}">
              <AddressList :addresses="selectedClient.addresses"
                @open-edit-address-modal="openEditAddressModal" v-if="viewType === display.LIST"/>
              <template v-if="viewType === display.TILE">
                <AddressTileViewItem v-for="(address, index) in selectedClient.addresses" :key="index"
                  :data="address" @open-edit-address-modal="openEditAddressModal"/>
              </template>
            </div>
        </section>

        <EmptyState
          style="top: calc(50% - 137px) !important; left: calc(50% - 150px) !important;"
          v-if="!clients.length && !loadingContractors"
          icon="job-client-empty-icon.svg"
          message="You don’t currently have any clients"
          :hasCustomContent="true">
          <Button
            @handle-click="onOpenModal"
            buttonText="add new client"
            :isActive="true"
            activeColor="#4F55F0"
            style="margin-top: 1.5rem !important;"/>
        </EmptyState>

        <Loader :show="loadingContractors" />

        <!-- MODALS -->
        <AddClientModal
          :show="addClientModal"
          @on-cancel="onCloseModal"
          @push-to-client-list="pushNewDataToClientList($event)"
          @push-to-address-list="pushNewDataToAddressList($event)"
          @update-client-list="updateClientList($event)"/>
        <AddAddressModal
          :show="addAddressModal"
          @on-cancel="onCloseAddressModal"
          @push-to-address-list="pushNewDataToAddressList($event)"
          @update-address-list="updateAddressList($event)"/>
        <ConfirmDeleteClientModal
          :show="confirmDeleteModal"
          :loading="loadingDelete"
          @on-confirm="onDeleteClient(selectedId)"
          @on-cancel="onCloseConfirmDeleteModal"/>
        <ConfirmDeleteClientAddressModal
          :show="confirmDeleteAddressModal"
          :loading="loadingDelete"
          @on-confirm="onDeleteClientAddress(selectedAddressId)"
          @on-cancel="onCloseConfirmDeleteAddressModal"/>
    </div>
</template>
<script>
// import _ from 'lodash';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import PageTitleWithCountComponent from '@/core/components/common/header/PageTitleWithCountComponent.vue';
import AddressList from '@/core/components/common/list/AddressList.vue';
import ClientsList from '@/core/components/common/list/ClientsList.vue';
import AddAddressModal from '@/core/components/common/modals/AddAddressModal.vue';
import AddClientModal from '@/core/components/common/modals/AddClientModal.vue';
import ConfirmDeleteClientAddressModal from '@/core/components/common/modals/ConfirmDeleteClientAddressModal.vue';
import ConfirmDeleteClientModal from '@/core/components/common/modals/ConfirmDeleteClientModal.vue';
import AddressTileViewItem from '@/core/components/common/tile/AddressTileViewItem.vue';
import UserTileViewItem from '@/core/components/common/tile/UserTileViewItem.vue';
import BackButton from '@/core/components/ui/BackButton.vue';
import Button from '@/core/components/ui/Button.vue';
import EmptyState from '@/core/components/ui/EmptyState.vue';
import Loader from '@/core/components/ui/Loader.vue';
import display from '@/core/constants/display';
import DisplayViewComponent from '@/modules/project-search/components/DisplayViewComponent.vue';
import { JOB_CLIENT_STORE } from '@/store/modules/job-client';
import { USERS_STORE } from '@/store/modules/users';

const SORT_NAME = 'name';
const SORT_EMAIL = 'email';

export default defineComponent({
  components: {
    PageTitleWithCountComponent,
    DisplayViewComponent,
    ClientsList,
    UserTileViewItem,
    BackButton,
    AddressList,
    AddClientModal,
    AddAddressModal,
    AddressTileViewItem,
    EmptyState,
    Button,
    ConfirmDeleteClientModal,
    Loader,
    ConfirmDeleteClientAddressModal
  },
  data() {
    return {
      display,
      viewType: display.LIST,
      addClientModal: false,
      addAddressModal: false,

      // temporary solution
      isOnViewDetailsScreen: false,
      // selectedClient: null,

      clients: [],

      addresses: [],

      loadingContractors: false,

      loadingDelete: false,

      sortType: SORT_NAME,

      popOverData: [
        {
          name: 'name',
        },
        {
          name: 'email'
        }
      ]
    };
  },

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
    ...mapGetters(JOB_CLIENT_STORE, ['selectedId', 'confirmDeleteModal', 'selectedClient', 'confirmDeleteAddressModal', 'selectedAddressId', 'selectedAddress']),

    actionText() {
      if (this.isOnViewDetailsScreen) {
        return 'Add address';
      }
      return 'Add client';
    }
  },

  methods: {
    ...mapActions(JOB_CLIENT_STORE, ['getUserContractors', 'deleteClient', 'setSelectedId', 'setConfirmDeleteModal', 'deleteClientAddress', 'setSelectedClient', 'resetJobClientFormState', 'resetAddressFormState', 'setAddressForm', 'setJobClientForm', 'setConfirmDeleteAddressModal', 'setSelectedAddress', 'setSelectedAddressId']),

    setView(view) {
      this.viewType = view;
    },

    onSort(name) {
      this.sortType = name;
      this.sortData(this.clients);
    },

    async initialize() {
      const userId = this.user.id;
      this.loadingContractors = true;

      await this.getUserContractors(userId).then((response) => {
        if (response) {
          this.sortData(response);
          this.clients = response;
        }
      }).catch((e) => {
        this.$notify.error({
          title: 'Error in retrieving contractors',
          message: e?.data?.message || 'Error occurred when trying to retrieve contractors.',
        });
      })
        .finally(() => {
          this.loadingContractors = false;
        });
    },

    async pushNewDataToClientList(client) {
      try {
        this.clients.push(client);
        this.resetJobClientFormState();
        this.sortData(this.clients);
      } catch (error) {
        console.error('Error in pushNewDataToClientList:', error);
      }
    },

    async pushNewDataToAddressList(newClientDetails) {
      const clientToUpdateAddress = this.clients.find((client) => client.id === newClientDetails.id);

      if (clientToUpdateAddress) {
        // Update the addresses property of the found client with newClientDetails.meta
        clientToUpdateAddress.addresses = newClientDetails.addresses;
        this.setSelectedClient(newClientDetails);
      }
    },

    async updateClientList(newClientDetails) {
      try {
        const clientToUpdate = this.clients.find((client) => client.id === newClientDetails.id);

        if (clientToUpdate) {
          // Update the meta property of the found client with newClientDetails.meta
          clientToUpdate.meta = newClientDetails.meta;
          this.resetJobClientFormState();
        } else {
          console.error('Client not found in the clients array.');
        }
      } catch (error) {
        console.error('Error in updateClientList:', error);
      }
    },

    updateAddressList(addresses) {
      const client = this.clients.find((i) => i.id === this.selectedId);
      const addressToUpdate = client.addresses.find((address) => address.id === this.selectedAddress.id);

      if (addressToUpdate) {
        const newAddressDetails = addresses.find((address) => address.id === addressToUpdate.id);
        addressToUpdate.meta = newAddressDetails.meta;
        this.resetAddressFormState();
        this.setSelectedAddressId(null);
        this.setSelectedAddress(null);
      }
    },

    async onDeleteClient(id) {
      this.loadingDelete = true;
      const userId = this.user.id;
      const params = { userId, id };

      await this.deleteClient(params).then((response) => {
        if (response) {
          this.removeClient(id);
          if (this.isOnViewDetailsScreen) {
            this.back();
          }
          this.$notify.success({
            title: 'Success',
            message: 'Client successfully deleted',
          });
        }
      }).catch((e) => {
        this.$notify.error({
          title: 'Error in deleting client',
          message: e?.data?.message || 'Error occurred when trying to delete contractors.',
        });
      })
        .finally(() => {
          this.loadingDelete = false;
          this.onCloseConfirmDeleteModal();
        });
    },

    async onDeleteClientAddress(addressId) {
      this.loadingDelete = true;
      const userId = this.user.id;
      const params = { userId, id: addressId };

      await this.deleteClientAddress(params).then((response) => {
        if (response) {
          this.removeClientAddress(this.selectedId, addressId);
          this.setSelectedAddressId(null);
          this.setSelectedAddress(null);
          this.$notify.success({
            title: 'Success',
            message: 'Address successfully deleted',
          });
        }
      }).catch((e) => {
        this.$notify.error({
          title: 'Error in deleting address',
          message: e?.data?.message || 'Error occurred when trying to delete contractors.',
        });
      })
        .finally(() => {
          this.loadingDelete = false;
          this.onCloseConfirmDeleteAddressModal();
        });
    },

    sortData(data) {
      if (this.sortType === SORT_NAME) {
        data.sort((a, b) => {
          return a?.meta?.name.localeCompare(b?.meta?.name);
        });
      }
      if (this.sortType === SORT_EMAIL) {
        data.sort((a, b) => {
          return a?.email.localeCompare(b?.email);
        });
      }
    },

    removeClient(clientIdToRemove) {
      const index = this.clients.findIndex((client) => client.id === clientIdToRemove);

      if (index !== -1) {
        this.clients.splice(index, 1);
      }
    },

    removeClientAddress(clientId, addressIdToRemove) {
      const clientIndex = this.clients.findIndex((client) => client.id === clientId);
      const addressIndex = this.clients[clientIndex].addresses.findIndex((address) => address.id === addressIdToRemove);

      if (addressIndex !== -1) {
        this.clients[clientIndex].addresses.splice(addressIndex, 1);
      }
    },

    onCloseConfirmDeleteModal() {
      this.setSelectedId(null);
      this.setConfirmDeleteModal(false);
    },

    onCloseConfirmDeleteAddressModal() {
      this.setSelectedId(null);
      this.setConfirmDeleteAddressModal(false);
    },

    openAddClientsModal() {
      this.addAddressModal = false;
      this.addClientModal = true;
    },

    openAddAddressModal() {
      this.addClientModal = false;
      this.addAddressModal = true;
    },

    onSelect(client) {
      // this.selectedClient = client;
      this.setSelectedClient(client);
      // console.log(this.selectedClient, 'selectedClient');
      this.setSelectedId(client.id);
      this.isOnViewDetailsScreen = true;
    },

    back() {
      this.setSelectedId(null);
      this.setSelectedClient(null);
      this.resetJobClientFormState();
      // this.selectedClient = null;
      this.isOnViewDetailsScreen = false;
    },

    onCloseModal() {
      this.setSelectedClient(null);
      this.resetJobClientFormState();
      this.addClientModal = false;
    },

    onOpenModal() {
      if (this.isOnViewDetailsScreen) {
        this.setAddressForm({
          addressLine: '',
          cityTown: '',
          postcode: ''
        });
        this.openAddAddressModal();
      } else {
        this.setJobClientForm({
          email: '',
          name: '',
          addressLine: '',
          cityTown: '',
          postcode: ''
        });
        this.openAddClientsModal();
      }
    },

    onCloseAddressModal() {
      this.resetAddressFormState();
      this.setSelectedAddressId(null);
      this.setSelectedAddress(null);
      this.addAddressModal = false;
    },

    openEditModal() {
      this.openAddClientsModal();
    },

    openEditAddressModal() {
      this.openAddAddressModal();
    }
  }
});
</script>
<style lang="scss" scoped>
.clients-page {
  position: relative;
  height: 100%;

  &__container.sticky {
    background: #F1F6FB;
    position: sticky;
    top: 0;
    z-index: 101;
    padding-bottom: 1rem;
    box-sizing: border-box;
  }
  &__container.grid {
    grid-template-columns: repeat(auto-fill, minmax(330px,1fr));
  }
  &__container.jc-fe {
    justify-content: flex-end !important;
  }
}
</style>
