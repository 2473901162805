import { ArrowDownBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PopOverSlot from '@/core/components/documents/PopOverSlot.vue';
import Button from '@/core/components/ui/Button.vue';
import { JOB_CLIENT_STORE } from '@/store/modules/job-client';
import { USERS_STORE } from '@/store/modules/users';
const DELETE = 'Delete';
const EDIT = 'Edit';
const CHAT = 'Chat';
export default defineComponent({
    components: {
        Button,
        ArrowDownBold,
        PopOverSlot
    },
    emits: ['show-delete-modal', 'update-member', 'on-select', 'open-edit-address-modal'],
    props: ['addresses'],
    data() {
        return {
            /* eslint-disable global-require */
            popOverData: [
                {
                    icon: `${require('@/assets/icons/filled-edit-icon.svg')}`,
                    name: EDIT,
                },
                {
                    icon: `${require('@/assets/icons/chat.svg')}`,
                    name: CHAT,
                },
                {
                    icon: `${require('@/assets/icons/delete-icon.svg')}`,
                    name: DELETE,
                },
            ],
        };
    },
    async created() {
        this.initialized();
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(JOB_CLIENT_STORE, ['selectedId', 'confirmDeleteAddressModal']),
    },
    methods: {
        ...mapActions(JOB_CLIENT_STORE, ['setSelectedId', 'setConfirmDeleteAddressModal', 'setSelectedClient', 'setSelectedAddressId', 'setSelectedAddress']),
        showDeleteModal(id) {
            this.$emit('show-delete-modal', id);
        },
        initialized() {
        },
        onUpdate(id) {
            const payload = {
                id,
            };
            if (id) {
                this.$emit('update-member', payload);
            }
        },
        formattedAddress(address) {
            const { addressLine, cityTown, postcode } = address.meta;
            return `${addressLine}, ${cityTown}, ${postcode}`;
        },
        handleEvent(eventName, address) {
            if (eventName === DELETE) {
                this.setSelectedAddressId(address.id);
                this.setConfirmDeleteAddressModal(true);
            }
            if (eventName === EDIT) {
                this.setSelectedAddress(address);
                this.$emit('open-edit-address-modal');
            }
        },
    }
});
